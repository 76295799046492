/* eslint-disable react/no-unescaped-entities */
import { graphql } from 'gatsby'
import React from 'react'

import Layout from '../../components/layout'
import SEO from '../../components/seo'
import '../../less/cms.less'

const MerchantAgreement = () => {
  return (
    <Layout>
      <SEO title="Merchant Agreement" />
      <div className="cms-container">
        <h1 className="cms-container__title">Restaurants Terms &amp; Conditions</h1>
        <div className="cms-container__wrapper">
          <p>
            This Merchant Agreement is a contract between you ("Merchant") and MealPal New Zealand Limited (NZBN 618 142
            368) ("MealPal" or "we") and governs your use of any MealPal website (including the Merchant Portal), mobile
            application (such as for iPhone or Android) or content, or products and/or services made available through
            MealPal (collectively, the "Site") or the participation or assistance in the provision of such content, or
            products and/or services by MealPal to its customers.
          </p>

          <ol>
            <li>
              TERMS AND CONDITIONS
              <ol>
                <li>
                  Acceptance of Terms. By accessing and/or using the Site or participating or engaging in or assisting
                  with the provision of any products or services by MealPal to its customers, you accept and agree to be
                  bound by this Merchant Agreement, just as if you had agreed to the Merchant Agreement in writing. If
                  you do not agree to the terms of this Merchant Agreement, do not use the Site or participate or engage
                  in or assist with the provision of services by MealPal to its customers.
                </li>
                <li>
                  Amendment of Terms. MealPal may amend this Merchant Agreement from time to time in its sole
                  discretion. Unless we provide a delayed effective date, all amendments will be effective upon posting
                  of such updated Merchant Agreement on the Site. We will notify you of any material amendments at least
                  14 days in advance of the amendments coming to effect. Your continued access to or use of the Site or
                  MealPal services after such posting constitutes your consent to be bound by the Merchant Agreement, as
                  amended. If you do not agree to the amendments, you may terminate this Merchant Agreement in writing
                  any time prior to the amendments coming into effect.
                </li>
                <li>
                  Additional Terms. In addition to the Merchant Agreement, you will also be subject to any additional
                  posted terms, guidelines, or rules applicable to such plan, offer, product, service or feature, which
                  may be posted on the Site and modified from time to time (including, without limitation and to the
                  extent applicable to a Merchant, the Terms governing the use of the Site found at{' '}
                  <a href="/nz/terms-and-conditions/" target="_blank">
                    https://mealpal.com/nz/terms-and-conditions/
                  </a>
                  . All such additional terms, guidelines or rules are hereby incorporated by reference into this
                  Merchant Agreement, provided that in the event of any conflict between such additional terms and this
                  Merchant Agreement, the Merchant Agreement shall control.
                </li>
                <li>
                  Defined Terms. Any terms in this Merchant Agreement which are capitalised but not specifically
                  defined, will have the meaning given in the Terms.
                </li>
              </ol>
            </li>
            <li>
              DISTRIBUTION OF MEALS:
              <ol>
                <li>
                  The Merchant agrees that in participating in the services offered by MealPal to its customers, it
                  will, subject to the reasonable directions of MealPal from time to time:
                  <ol>
                    <li>
                      offer meals to MealPal customers daily, Monday through Friday when the Merchant is open for
                      business;
                    </li>
                    <li>
                      select one meal per day from the Merchant's regular menu in the Merchant Portal by 5pm the weekday
                      prior to the scheduled delivery date of the relevant meal. If the Merchant doesn't select a meal,
                      MealPal will select the meal;
                    </li>
                    <li>
                      set a daily quantity of meals that Merchant will provide to MealPal customers, understanding that
                      MealPal has the right at its sole discretion to require the Merchant to offer a minimum and/or
                      maximum quantity of meals to MealPal customers;
                    </li>
                    <li>provide meals to MealPal customers based on the daily reservation email sent;</li>
                    <li>
                      Provide MealPal customers with meals of the same size and quality as would be prepared for
                      customers ordering off of the regular restaurant menu. Meals that are provided at a smaller
                      portion size than the normal restaurant meal portion size, as reported by MealPal customers, will
                      not be paid for.
                    </li>
                    <li>
                      pay for and indemnify MealPal against all of the Merchant's obligations to pay any taxes
                      (including GST) and issue a tax invoice to MealPal for any supply that is, to any extent, a
                      taxable supply made by the Merchant to MealPal; and
                    </li>
                    <li>
                      in the event that MealPal overpays the Merchant, MealPal has the right to offset the overpayment
                      against future payments to the Merchant.
                    </li>
                    <li>
                      The Merchant acknowledges and agrees that MealPal has the right to modify meal schedule offered in
                      its sole discretion.
                    </li>
                  </ol>
                </li>
              </ol>
            </li>
            <li>
              QUALITY AND SAFETY
              <ol>
                <li>
                  The Merchant is solely responsible for the quality and safety of all meals supplied to MealPal
                  subscribers. It shall provide such warnings to MealPal subscribers as are appropriate, required and
                  necessary in accordance with the requirements of any applicable legislative, governmental, regulatory
                  or industry body (including, but not limited to, Food Standards Australia and New Zealand) as well as
                  the reasonable directions of such body, and all applicable laws and regulations relating to the meals
                  and their ingredients including, without limitation, all necessary information relating to food
                  allergies.
                </li>
                <li>
                  The Merchant will hold MealPal, its related companies (as defined under the Companies Act 1993),
                  directors, officers and employees harmless and shall fully and effectively indemnify each of them from
                  and against all and any costs, claims, liabilities and expenses received, suffered, or incurred
                  whatsoever and howsoever arising by reason of the Merchant providing meals to MealPal's subscribers.
                </li>
              </ol>
            </li>
            <li>
              OTHER TERMS AND CONDITIONS:
              <ol>
                <li>The terms of the Merchant Agreement shall be kept confidential, except where required by law.</li>
                <li>
                  This Merchant Agreement will automatically renew each year unless terminated by either party with 30
                  days' advance written notice.
                </li>
                <li>
                  The Merchant's liability under any matter indemnified in these terms shall survive and not be affected
                  by termination of the Merchant Agreement.
                </li>
                <li>
                  Display a visible MealPal sign with the QR code for all customers to scan in order to pick up their
                  meal.
                </li>
                <li>
                  The restaurant will be charged a 3.5% payment handling fee. This will be deducted from the weekly
                  payment.
                </li>
                <li>
                  Merchant agrees to provide accurate and complete banking details to MealPal. If the Merchant does not
                  provide payment details for over one year, they agree to relinquish these funds to MealPal.
                </li>
                <li>
                  MealPal will pay the restaurant for all meals ordered or all meals picked up via a successful QR code
                  scan, whichever is greater.
                </li>
              </ol>
            </li>
          </ol>
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`

export default MerchantAgreement
